const App: React.FC = () => {
  return (
    <div
      style={{
        fontFamily: "Roboto",
        fontSize: "20px",
        marginTop: "200px",
        alignSelf: "center",
        textAlign: "center",
        aspectRatio: 736 / 89,
        color: "black",
      }}
    >
      <img src="logo.png" height="300px" alt="logo" />
      <br />
      <br />
        Global software development
      <br />
    </div>
  );
};

export default App;
